.App {
  background-color: black;
  width: 100vw;
  height: 100vh;
}

.App-logo {
  image-rendering: pixelated;
  pointer-events: none;
  width: 100%;
  max-width: 600px;
  height: auto;
  user-select: none;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
